// extracted by mini-css-extract-plugin
export var mySlides = "Home-module--mySlides--1OinQ";
export var slideImages = "Home-module--slideImages--_rJzq";
export var footerStrip = "Home-module--footerStrip--m3Jg-";
export var caption = "Home-module--caption--YyZR0";
export var indicators = "Home-module--indicators--3JTZC";
export var active = "Home-module--active--1V04S";
export var vCont = "Home-module--vCont--Zcygn";
export var leftCont = "Home-module--leftCont--1jwOL";
export var rightCont = "Home-module--rightCont--2r6Ut";
export var impact = "Home-module--impact--27Cvn";
export var impactCont = "Home-module--impactCont--1yCDZ";
export var impactContIn = "Home-module--impactContIn--1ywuI";
export var impactFig = "Home-module--impactFig--Larfs";
export var imFigures = "Home-module--imFigures--3QYiJ";
export var partners = "Home-module--partners--28i49";
export var partnersS = "Home-module--partnersS--1sS_l";
export var partnerCont = "Home-module--partnerCont--3cfj2";
export var partnerLogo = "Home-module--partnerLogo--2IOP4";
export var news = "Home-module--news--2sXoR";
export var Headings = "Home-module--Headings--1177k";
export var slider = "Home-module--slider--1FaT8";